import { Injectable } from '@angular/core';
import { TimeSpentBase } from 'src/app/_shared/event/timespent-base';

@Injectable({ providedIn: 'root' })

export class TimeSpentService{

    getHours() {
        let hours: TimeSpentBase<any>[] = [];

        for (let i = 0; i < 16; i++) {
            hours.push(
                new TimeSpentBase({
                    value: i,
                    key: 'hour',
                    valueFormat: 'h',
                    label: `${i} h`,
                    selected: false
                })
            );
        }

        return hours;

    }

    getMinutes() {
        let minutes: TimeSpentBase<any>[] = [];

        for (let i = 0; i <= 45; i += 15) {
            minutes.push(
                new TimeSpentBase({
                    value: i,
                    key: 'minute',
                    valueFormat: 'min',
                    label: `${i} min`,
                    selected: false,
                })
            );
        }

        return minutes;
    }
}


