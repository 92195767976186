export class YearListBase<T> {
    num: number;
    value: string;

    constructor(options: {
        num?: number,
        value?: string
    } = {}) {
        this.num = options.num;
        this.value = options.value;
    }
}
