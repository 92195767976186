import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { EditEventService, DataService } from 'src/app/_services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnDestroy {

    @Input() currentProjectId: number;
    @Input() currentCustomerId: number;

    @Output() onProjectIdSelected = new EventEmitter<number>();

    allProjectsForCustomer: any = [];

    subscription = new Subscription();

    constructor(private editEventService: EditEventService, private dataService: DataService) { }

    populateList() {
        this.subscription = this.dataService.selectedCustomerId.subscribe(
            (id: any) => {
                this.currentCustomerId = id;
                this.editEventService.populateEventWithProjects(this.currentCustomerId).subscribe(
                    (res: any) => {
                        this.allProjectsForCustomer = res.projects;
                    },
                    err => console.error(err)
                );
            },
            err => console.error(err)
        );
    }

    projectClicked(projectId: number) {
        this.currentProjectId = projectId;
        this.onProjectIdSelected.emit(projectId);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
