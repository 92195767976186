import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { HomeService, ExportService } from 'src/app/_services';
import { DatePipe } from '@angular/common';
import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChevronDialogComponent } from 'src/app/_components/home/chevron/chevron-dialog.component';
import { Router } from '@angular/router';
import {MonthListBase} from "../../../_shared/calendar/monthlist-base";
import {YearListBase} from "../../../_shared/calendar/yearlist-base";
import {DateListService} from "../../../_services/home/datelist.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {ImportDialogComponent} from "../../../_components/home/import-dialog/import-dialog.component";
import {HomeComponent} from "../../../_components";
import {Subscription} from "rxjs";
import { VacationDialogComponent } from '../../../_components/home/vacation/vacation-dialog.component';

@Component({
    selector: 'header-shared',
    templateUrl: './header-shared.component.html',
    styleUrls: ['./header-shared.component.scss'],
})
export class HeaderSharedComponent implements OnInit {

    response = {
        calendar: [],
        expendedTime: 0,
        performanceIndicator: 0,
        user: { Id: 0, FullName: '', ChevronName: '', progress: 0, totalHours: 0, Freelancer: false, unusedVacationDays: 0  }
    };
    
    @Input() public customers: any[] = [];
    @Input() public projects: any[] = [];
    @Input() public fileToUpload: File = null;
    @Input() public recentProject: any[] = [];
    @Input() isHomePage: boolean = false;

    date: any = new Date();
    imgpath: string;
    needVacation: boolean = false;
    tooMuchVacation: boolean = false;
    unusedVacation: number;
    isShown: boolean = false;

    selectedMonth: number;
    monthLevels: MonthListBase<any>[] = [];

    selectedYear: number;
    yearLevels: YearListBase<any>[] = [];
    performanceRaiting: string;
    loading: boolean = false;
    urlDate: string;
    private subscription: Subscription;
    
    constructor(
        private homeService: HomeService, private homeHelper: HomeHelper,
        private modalService: NgbModal, private toastr: ToastrService, public dialog: MatDialog,
        private DatePipe: DatePipe, private dateListService: DateListService, public exportService: ExportService,
        private router: Router, private homeComponent: HomeComponent, private cdr: ChangeDetectorRef) { }

    async ngOnInit() {
        await this.redirectToMonthCheck();
        await this.redirectToYearCheck();
        this.selectedMonth = this.date.getMonth() + 1;
        this.selectedYear = this.date.getFullYear();
        this.yearLevels = this.dateListService.getYearsList();
        this.monthLevels = this.dateListService.getMonthsList(this.selectedYear);
        await this.homeService.getUserStatistics(this.date).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.Id = res.user.Id;
                this.response.user.FullName = res.user.FullName;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.response.user.unusedVacationDays = res.user.UnusedVacationDays;
                this.response.user.Freelancer = res.user.Freelancer;
                this.CheckVacations(this.response.user.unusedVacationDays);
                this.NiceTooltipHelper(res.performanceIndicator);
            },
            err => console.error(err)
        );

        this.subscription = this.homeService.data$.subscribe(data => {
            if (data == null) return;
            this.response.expendedTime = data.expendedTime;
            this.response.performanceIndicator = data.performanceIndicator;
            this.response.user.unusedVacationDays = data.user.UnusedVacationDays;
        });

        this.RandomImage();
        setTimeout(res => this.isShown = true, 500);
    }
    
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private RandomImage() {
        let a = Math.floor(Math.random() * 10);
        return this.imgpath = "/assets/img/random/pix" + a + ".png";
    }
    
    private CheckVacations(unusedVacationDays) {
        if (unusedVacationDays >= 10 && this.selectedMonth >= 9 && this.response.user.Freelancer != true) {
            this.needVacation = true;
            setTimeout(res => this.vacationModal(), 500);
        }
        if (unusedVacationDays < 0) {
            this.tooMuchVacation = true;
            this.unusedVacation = Math.abs(this.response.user.unusedVacationDays)
        }
    }

    public onUpdateUserStatistics(res: any) {
        this.response.user.unusedVacationDays = res.unusedVacationDays;
        this.CheckVacations(res.unusedVacationDays);
    }

    chevronClick() {
        this.homeService.getUserStatistics(this.date).subscribe(
            (res: any) => {
                const modalRef = this.modalService.open(ChevronDialogComponent, { centered: true });
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                modalRef.componentInstance.chevronName = this.response.user.ChevronName;
                modalRef.componentInstance.progress = this.response.user.progress;
                modalRef.componentInstance.totalHours = this.response.user.totalHours;
                modalRef.result.catch(() => false);
            },
            err => console.error(err)
        );
    }

    onLogout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('month');
        sessionStorage.removeItem('year');
        this.router.navigate(['/user/login']);
    }

    redirectToMonthCheck() {
        if (sessionStorage.getItem('month') === null) {
            sessionStorage.setItem('month', this.date.getMonth() + 1);
        }
        let monthCheck = sessionStorage.getItem('month');
        if (this.selectedMonth != parseInt(monthCheck)) {
            this.date.setMonth(parseInt(monthCheck) - 1);
        }
    }

    redirectToYearCheck() {
        if (sessionStorage.getItem('year') === null) {
            sessionStorage.setItem('year', this.date.getFullYear());
        }
        let yearCheck = sessionStorage.getItem('year');
        if (this.selectedYear != parseInt(yearCheck)) {
            this.date.setYear(parseInt(yearCheck));
        }
    }

    changeMonth() {
        this.selectedMonth = +this.selectedMonth;
        this.date.setMonth(this.selectedMonth - 1);
        sessionStorage.setItem('month', this.selectedMonth.toString());
        this.ngOnInit();
        this.homeComponent.ngOnInit();
    }

    changeYear() {
        this.selectedYear = +this.selectedYear;
        this.date.setYear(this.selectedYear);
        sessionStorage.setItem('year', this.selectedYear.toString());
        this.ngOnInit();
        this.homeComponent.ngOnInit();
    }

    PrintUserReport() {
        this.loading = true;
        this.urlDate = this.DatePipe.transform(this.date, 'yyyy-MM');
        this.exportService.createPdf(this.urlDate)
            .subscribe((res: any) => {
                //console.log(res.headers);
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                //console.log(filename);
                let file = new Blob([res.body], { type: 'application/pdf' });
                let status_report = document.createElement('a');
                status_report.download = filename;
                status_report.href = URL.createObjectURL(file);
                window.open(status_report.href);
                //status_report.click();
                setTimeout(res => this.loading = false, 200);
            });
    }

    private NiceTooltipHelper(performanceIndicator) {
        if (performanceIndicator < 50) {
            this.performanceRaiting = "Work Harder, Comrade!"
        }
        else if (performanceIndicator < 100 && performanceIndicator > 50) {
            this.performanceRaiting = "Not bad!"
        }
        else if (performanceIndicator > 200) {
            this.performanceRaiting = "Crazy!"
        }
        else {
            this.performanceRaiting = "Awesome!"
        }
    }

    onFileDropped($event) {
        for (const item of $event) {
            this.fileToUpload = item;
        }
        this.validateFile($event);
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        this.validateFile(files);
    }

    async validateFile(files) {
        let file = files[0];
        if (file.size < 10) {
            this.toastr.error("We are sorry. Attached file is empty", 'File is empty');
        } else if (file.type != "application/vnd.ms-excel" && file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            && file.type != "text/csv") {
            this.toastr.error("We are sorry. Format of data is not correct we support csv, xls, and xlsx", 'Incorrect format');
        } else {
            this.dialogClick();
        }
    }

    vacationModal() {
        var showModal = sessionStorage.getItem('modal-vacation');
        if (localStorage.getItem('token') != null && showModal != 'true') {
            if (!navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|BB|PlayBook|Windows Phone/i)) {
                sessionStorage.setItem('modal-vacation', 'true');
                const modalRef = this.modalService.open(VacationDialogComponent, { centered: true });
                modalRef.result.catch(() => false);
            }
        }
    }

    dialogClick() {
        const modalRef = this.modalService.open(ImportDialogComponent, { centered: true });
        modalRef.componentInstance.fileToUpload = this.fileToUpload;
        modalRef.componentInstance.customers = this.customers;
        modalRef.componentInstance.projects = this.projects;
        modalRef.componentInstance.recentProject = this.recentProject;
        modalRef.result.catch(() => false);
    }

    updateUserStatistics() {
        this.homeService.updateUserStatistics(this.date).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.response.user.unusedVacationDays = res.unusedVacationDays;
            },
            err => console.log(err)
        );
    }
}
