import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from "@angular/router";
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../../_services/user/user.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
    selector: 'app-reset-pass',
    templateUrl: './reset-pass.component.html',
    styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

    formModel = {
        Password: '',
        ConfirmPassword: ''
    }

    passLength: boolean = true;
    passLowercaseLetter: boolean = true;
    passCapitalLetter: boolean = true;
    passNumber: boolean = true;

    tokenParams: Params;

    constructor(private router: Router, private route: ActivatedRoute, private service: UserService, private toastr: ToastrService) {
        this.route.params.subscribe(params => this.tokenParams = params.token);
    }

    validatePassword(formData) {
        return formData.Password.length >= 8;
    }
    validateLower(formData) {
        var regex = /[a-z]/;
        return regex.test(formData.Password);
    }
    validateUpper(formData) {
        var regex = /[A-Z]/;
        return regex.test(formData.Password);
    }
    validateNumber(formdata) {
        var regex = /\d/;
        return regex.test(formdata.Password);
    }


    ngOnInit() {
        this.service.isTokenValid(this.tokenParams)
            .subscribe((res: any) => {
                this.toastr.success(res.message);
            },
                err => {
                    if (err.status == 401)
                        console.log(err.err.message);
                    this.router.navigateByUrl("/user/login");
                    this.toastr.error(err.error.message, 'Authentication failed.');

                });
    }

    onSubmit(form: NgForm) {
        this.passLength = this.validatePassword(form.value);
        this.passLowercaseLetter = this.validateLower(form.value);
        this.passCapitalLetter = this.validateUpper(form.value);
        this.passNumber = this.validateNumber(form.value);
        if (this.passLength && this.passCapitalLetter && this.passNumber && this.passLowercaseLetter) {
            if (this.service.isPassEqual(form.value)) {
                this.service.restorePass(form.value, this.tokenParams)
                    .subscribe((res: any) => {
                        this.toastr.success(res.message);
                        this.router.navigateByUrl("/user/login");
                    },
                        err => {
                            if (err.status == 401)
                                console.log(err.err.message);
                            this.toastr.error(err.error.message, 'Authentication failed. Via token');
                        });
            } else {
                this.toastr.error("Passwords must match");
            }
        }
    }
}
