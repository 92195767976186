import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../../_services';

import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    isChecked: boolean = false;

    formModel = {
        Login: '',
        Password: ''
    }
    currentYear: number;
    currentDate: string;

    constructor(private service: UserService, private router: Router, private toastr: ToastrService,
        private DatePipe: DatePipe) { }

    ngOnInit() {
        this.currentDate = this.DatePipe.transform(new Date().toString(), 'yyyy-MM-dd');
        if (localStorage.getItem('token') != null) {
            if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|BB|PlayBook|Windows Phone/i)) {
                this.router.navigateByUrl(`/event-list/${this.currentDate}`);
            } else {
                this.router.navigateByUrl('/home');
            }
        }
        this.currentYear = new Date().getFullYear();
    }

    onSubmit(form: NgForm) {
        this.service.login(form.value, this.isChecked ? 'TRUE' : 'FALSE').subscribe(
            (res: any) => {
                localStorage.setItem('token', res.token);
                if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|BB|PlayBook|Windows Phone|MeeGo/i)) {
                    this.router.navigateByUrl(`/event-list/${this.currentDate}`);
                } else {
                    this.router.navigateByUrl('/home');
                }
            },
            err => {
                if (err.status == 400) {
                    console.log(err.error.message);
                    this.toastr.error(err.error.message, 'Authentication failed.');
                }
            });
    }
}
