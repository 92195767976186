﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/_services';
import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import { NgStyle } from '@angular/common';


@Component({
    selector: 'app-chevron-dialog',
    templateUrl: './chevron-dialog.component.html',
    styleUrls: ['./chevron-dialog.component.scss']
})
export class ChevronDialogComponent implements OnInit {

    @Input() chevronName: string = '';
    @Input() progress: number = 0;
    @Input() totalHours: number = 0;
    date: any = new Date();
    position: any;
    constructor(private activeModal: NgbActiveModal, private homeService: HomeService, private homeHelper: HomeHelper, ) { }

    ngOnInit() {
        this.loadScript();
    }
    public dismiss() {
        this.activeModal.dismiss();
    }

    public loadScript() {
        var elem = document.getElementById("percent");
        this.position = this.homeHelper.getPercentPos(this.chevronName);
        elem.innerText = this.progress + '%';
        var width = 0;
        var heigth = 137 * (this.position.vertical - 1);
        width = (this.progress * 110 / 100);
        elem.style.marginLeft = 138.66 * (this.position.horizont - 1) + 41 + width + 'px';
        elem.style.marginTop = heigth + 118 + 'px';
    }
    ngOnDestroy(): void {
        // this.eventList.unsubscribe();
    }

}