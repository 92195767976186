import { Injectable } from '@angular/core';
import { YearListBase } from 'src/app/_shared/calendar/yearlist-base';
import { MonthListBase } from 'src/app/_shared/calendar/monthlist-base';

@Injectable({ providedIn: 'root' })

export class DateListService{

    getYearsList() {
        const years: YearListBase<any>[] = [];

        const yearValue = new Date().getFullYear() + 1; // + 1 - make next year available for selection

        for (let i = yearValue; i > 2004; i--) {
            years.push( new YearListBase(
                {
                    num: i,
                    value: String(i)
                }
            ));
        }

        return years;
    }

    getMonthsList(year) {
        const months: MonthListBase<any>[] = [
            { num: 1,   value: 'January',   firstDate: `${year}-01-01`, lastDate: `${year}-01-31` },
            { num: 2,   value: 'February',  firstDate: `${year}-02-01`, lastDate: `${year}-02-29` },
            { num: 3,   value: 'March',     firstDate: `${year}-03-01`, lastDate: `${year}-03-31` },
            { num: 4,   value: 'April',     firstDate: `${year}-04-01`, lastDate: `${year}-04-30` },
            { num: 5,   value: 'May',       firstDate: `${year}-05-01`, lastDate: `${year}-05-31` },
            { num: 6,   value: 'June',      firstDate: `${year}-06-01`, lastDate: `${year}-06-30` },
            { num: 7,   value: 'July',      firstDate: `${year}-07-01`, lastDate: `${year}-07-31` },
            { num: 8,   value: 'August',    firstDate: `${year}-08-01`, lastDate: `${year}-08-31` },
            { num: 9,   value: 'September', firstDate: `${year}-09-01`, lastDate: `${year}-09-30` },
            { num: 10,  value: 'October',   firstDate: `${year}-10-01`, lastDate: `${year}-10-31` },
            { num: 11,  value: 'November',  firstDate: `${year}-11-01`, lastDate: `${year}-11-30` },
            { num: 12,  value: 'December',  firstDate: `${year}-12-01`, lastDate: `${year}-12-31` }
        ];


        return months;
    }

    getConnectedList(calendar: any) {
        const result = [];

        for(const date of calendar) {
            result.push(date.EventDate);
        }

        return result;
    }
}
