import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class CreateEventService {

    constructor(private http: HttpClient) {}

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': this.token
        })
    };

    populateEventWithData(): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/`, this.headers);
    }

    saveEvent(event: any) {
        return this.http.put(this.BaseUrl + `/Event/UpdateEvent`, event, this.headers);
    }


}
