import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class Validator {

    constructor() {}

    public static validate(value: any): boolean {
        return (value === undefined || 
        value === null || 
        value === 0 ||
        (typeof value == 'object' && Object.keys(value).length === 0) || 
        (typeof value === 'string' && value.trim().length === 0));
    }

    public static validateSpecChars(value: string): boolean {
        var specCharsRegex = /[\<\>]/g;// /[\@\#\$\%\^\&\*\(\)\[\]\"\:\{\}\|\<\>\\\/]/g;
        return specCharsRegex.test(value);
    }

}