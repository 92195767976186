import { Calendar } from './calendar.model';

export class CalendarResponse {

    calendar: [Calendar];
    expendedTime: number;
    performanceIndicator: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
