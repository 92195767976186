import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditEventService, DataService } from 'src/app/_services';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

    @Input() currentCustomerId: number;
    @Input() selectedDate: string;
    @Output() onCustomerIdSelected = new EventEmitter<number>();
    @Output() onCustomersDownloadFinished = new EventEmitter();

    eventCustomers: any;

    constructor(private editEventService: EditEventService, private dataService: DataService) { }

    populateList() {
        this.editEventService.populateEventWithCustomers(this.selectedDate).subscribe(
            (res: any) => {
                this.eventCustomers = res.customers;
                let customerAvailable = this.eventCustomers.filter(customer => customer.Id == this.currentCustomerId).length == 1;
                if (customerAvailable) {
                    this.dataService.sendCustomerId(this.currentCustomerId);
                }
                this.onCustomersDownloadFinished.emit();
            },
            err => console.error(err)
        );
    }

    customerClicked(customerId: number) {
        this.currentCustomerId = customerId;
        this.onCustomerIdSelected.emit(customerId);
    }

}
