import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthErrorService implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error) {
      const router = this.injector.get(Router);
    if (error != null && error.rejection != null && (error.rejection.status === 401 || error.rejection.status === 403)) {
      router.navigate(['/user/login']);
      }
    throw error;
  }
}