﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })

export class ExportService {

    constructor(private http: HttpClient) { }

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;

    /*createPdf(userId: number, date:string): any {
        return this.http.get<any>(this.BaseUrl + `/Pdf/Create/${userId}/${date}`, {'observe': 'response', 'responseType': 'blob' as 'json'}).toPromise();
    }*/

    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': this.token
        }), 'responseType': 'blob' as 'json',
        observe: "response" as 'body'
    }


    createPdf(date: string):any {
        const tr = this.http.get<any>(this.BaseUrl + `/Pdf/Create/${date}`, this.headers);
        //console.log(tr);
        return tr;
    }

    /*formatDate(date: any): string {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    }*/
}