import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderSharedComponent } from './components/header/header-shared.component';
import {FormsModule} from "@angular/forms";
import {DragAndDropDirective} from "../_directive/drag-and-drop.directive";
import {FooterSharedComponent} from "./components/footer/footer-shared.component";

@NgModule({
    declarations: [HeaderSharedComponent, DragAndDropDirective, FooterSharedComponent],
    imports: [
        NgbModule,
        CommonModule,
        SharedRoutingModule,
        FormsModule,
    ],
    exports: [HeaderSharedComponent, FooterSharedComponent, DragAndDropDirective]
})
export class SharedModule { }
