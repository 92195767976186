import { Component, OnInit, OnDestroy, ViewChild, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EventListService, DataService, HomeService } from 'src/app/_services/';
import { DatePipe } from '@angular/common';
import { SlideInOutAnimation } from 'src/app/animations';
import { Subscription } from 'rxjs';
import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HeaderSharedComponent } from '../../shared/components/header/header-shared.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'ddd, MMM DD, Y',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'EEEE, MMM DD, Y',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): any {
        return date ? date.year + "-" + ('0' + date.month).slice(-2)
            + "-" + ('0' + date.day).slice(-2) : null
    }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(' ');
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        var abbrMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var abbrDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if (date) {
            let d = new Date(date.year, date.month - 1, date.day);
            return abbrDays[d.getDay()] + ", " + (abbrMonths[d.getMonth()]) + " " + ('0' + date.day).slice(-2) + ", " + d.getFullYear();
        }
        return '';
    }
}


@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss'],
    animations: [SlideInOutAnimation],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
})
export class EventListComponent implements OnInit, OnDestroy {
    @ViewChild('datePicker') picker: any;

    eventList: any = [];
    date: any;

    urlDate: string;
    deploymentVersion: string;

    animationState = 'none';

    totalHours: number = 0;

    hoursForCustomer = 0;

    response = {
        calendar: [],
        expendedTime: 0,
        performanceIndicator: 0,
        user: { Id: 0, FullName: '', ChevronName: '', progress: 0, totalHours: 0 }
    };

    subscription = new Subscription();
    source: string;
    currentYear: number;
    dateForPicker: string;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private eventListService: EventListService,
        private dataService: DataService, private location: Location, private DatePipe: DatePipe,
        private homeService: HomeService, private homeHelper: HomeHelper) { }

    ngOnInit() {
        if (localStorage.getItem('token') == null) {
            this.router.navigateByUrl('');
        }

        this.deploymentVersion = localStorage.getItem('deploymentVersion');

        this.subscription = this.dataService.currentDate.subscribe(date => {
            this.date = date;
            if (Object.entries(this.date).length !== 0)
                this.mergeEventsWithSameCustomersToArray();
        });

        this.urlDate = (this.activatedRoute.snapshot.paramMap.get('date'));
        this.source = (this.activatedRoute.snapshot.paramMap.get('source')); 

        if (Object.entries(this.date).length === 0 && this.date.constructor === Object) {
            this.eventListService.populateEventList(this.urlDate).subscribe(
                (res: any) => {
                    this.date = res.eventList;
                    this.mergeEventsWithSameCustomersToArray();
                    res.eventList.forEach(element => {
                        this.totalHours += element.MinutesSpent;
                    });
                },
                err => console.error(err)
            );
        } else {
            this.mergeEventsWithSameCustomersToArray();
            this.date.forEach(element => {
                this.totalHours += element.MinutesSpent;
            });
            this.totalHoursToString();
        }
        this.currentYear = new Date().getFullYear();
        this.dateForPicker = this.urlDate;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    backClicked() {
        this.location.back();
    }

    editClick(eventId: string) {
        this.router.navigateByUrl(`/edit-event/${this.urlDate}/${eventId}/${this.source}`);
    }

    copyClick(event: any) {
        this.eventListService.copyEvent(event)
            .subscribe((res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.totalHours += event.MinutesSpent;

            },
                err => console.log(err));
    }

    deleteClick(event: any) {
        this.eventListService.deleteEvent(event)
            .subscribe((res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.totalHours -= event.MinutesSpent;
            },
                err => console.error(err));

        this.homeService.updateUserStatistics(new Date(this.urlDate)).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.homeService.updateData(new Date(this.urlDate));
            },
            err => console.log(err)
        );
    }

    addEventClick() {
        this.source = "event-list";
        this.router.navigateByUrl(`/create-event/${this.urlDate}/${this.source}`);
    }

    previousDateArrowClick() {
        let newDate = new Date(this.urlDate);
        this.totalHours = 0;
        newDate.setDate(newDate.getDate() - 1);
        this.urlDate = this.DatePipe.transform(newDate, 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        //this.animationState = 'right';
        //this.resetAnimationState();

        this.dateForPicker = this.urlDate;
    }

    nextDateArrowClick() {
        let newDate = new Date(this.urlDate);
        this.totalHours = 0;
        newDate.setDate(newDate.getDate() + 1);
        this.urlDate = this.DatePipe.transform(newDate, 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        //this.animationState = 'left';
        //this.resetAnimationState();

        this.dateForPicker = this.urlDate;
    }

    //resetAnimationState() {
    //    setTimeout(() => {
    //        this.animationState = 'none';
    //    }, 500);
    //}

    onLogout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('month');
        sessionStorage.removeItem('year');
        this.router.navigate(['/user/login']);
    }

    private mergeEventsWithSameCustomersToArray() {
        let customerIdArr = [];
        let tempArr = [];

        this.date.forEach(element => {
            if (customerIdArr.indexOf(element.Project.Customer.Id) < 0)
                customerIdArr.push(element.Project.Customer.Id);
        });

        this.eventList = [];

        for (let customerId of customerIdArr) {
            tempArr = this.date.filter(element => element.Project.Customer.Id == customerId);
            this.eventList.push(tempArr);
        }

        this.addTotalHoursForSingleCustomer();
    }

    private addTotalHoursForSingleCustomer() {
        for (let i of this.eventList) {
            let totalHoursForCustomer = 0;

            i.forEach(element => {
                totalHoursForCustomer += element.MinutesSpent;
            });
            if ((totalHoursForCustomer % 60) < 10) {
                i.newProperty = Math.floor(totalHoursForCustomer / 60) + "h 0" + (totalHoursForCustomer % 60) + " min ";
            } else {
                i.newProperty = Math.floor(totalHoursForCustomer / 60) + "h " + (totalHoursForCustomer % 60) + " min ";
            }
        }
    }
    private totalHoursToString() {
        if ((this.totalHours % 60) < 10) {
            return Math.floor(this.totalHours / 60) + "h 0" + (this.totalHours % 60) + " min ";
        } else {
            return Math.floor(this.totalHours / 60) + "h " + (this.totalHours % 60) + " min ";
        }
    }

    changeDay(day: any) {
        this.totalHours = 0;
        this.urlDate = this.DatePipe.transform(new Date(day.year, day.month - 1, day.day), 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        this.dateForPicker = this.urlDate;
    }

    isWeekend() {
        return this.DatePipe.transform(this.urlDate, 'EEEE');
    //    return (this.date.getDay() === 6) || (this.date.getDay() === 0);
    }
}
