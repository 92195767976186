import { Injectable } from "@angular/core";

import { Validator } from './validator';

@Injectable({ providedIn: 'root' })

export class EventValidation {

    constructor() {}

    public validateEvent(data: any): any {
        
        let errors: any = {};

        if(Validator.validate(data.Customer.Id)) {
            errors.Customer = "Please select a customer";
        }

        if(Validator.validate(data.Project.Id)) {
            errors.Project = "Please select a project";
        }

        if (Validator.validate(data.UserComment)) {
            errors.UserComment = "Please enter description";
        } else if (Validator.validateSpecChars(data.UserComment)) {
            errors.UserComment = "The description should not contain special characters ('<', '>')";
        }

        return { 
            errors : errors,
            isValid: Validator.validate(errors)
        };
    }

}