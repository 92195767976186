﻿import {Component, OnInit} from '@angular/core';
import {HomeService} from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
    selector: 'footer-shared',
    templateUrl: './footer-shared.component.html',
    styleUrls: ['./footer-shared.component.scss']
})
export class FooterSharedComponent implements OnInit {
    currentYear: number;
    deploymentVersion: string;
    
    constructor(private router: Router, private homeService: HomeService,) { }
    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        this.homeService.getDeploymentVersion().subscribe(
            (res: any) => {
                localStorage.setItem('deploymentVersion', res);
            },
            err => console.log(err)
        );
        this.deploymentVersion = localStorage.getItem('deploymentVersion');
    }
}