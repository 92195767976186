import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DataService, EditEventService } from 'src/app/_services';

@Component({
    selector: 'app-recent-projects',
    templateUrl: './recent-projects.component.html',
    styleUrls: ['./recent-projects.component.scss']
})
export class RecentProjectsComponent implements OnInit {

    @Input() currentProjectId: number;
    @Input() currentCustomerId: number;
    @Input() selectedDate: string;
    @Output() onRecentProjectSelected = new EventEmitter<any>();

    constructor(private editEventService: EditEventService, private dataService: DataService) { }

    allRecentProjects: any;

    ngOnInit() {
        this.editEventService.populateEventWithRecentProjects(this.selectedDate).subscribe(
            (res: any) => {
                this.allRecentProjects = res.recentProjectsWithCustomers;
            },
            err => console.error(err)
        );
    }

    recentProjectClicked(projectId: number, customerId: number) {
        this.onRecentProjectSelected.emit({ projectId, customerId });
    }

}
