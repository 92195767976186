import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class EditEventService {

    constructor(private http: HttpClient) {}

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': this.token
        })
    };

    populateEventWithData(eventId: number): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetExistingEvent/${eventId}`, this.headers);
    }

    populateEventWithCustomers(date: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetCustomers/${date}`, this.headers);
    }

    populateEventWithProjects(customerId: number): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetProjectsByCustomerId/?customerId=${customerId}`, this.headers);
    }

    populateEventWithRecentProjects(date?: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetRecentProjects/${date != null ? date : 'null'}`, this.headers);
    }

    updateEvent(event: any) {
        return this.http.put(this.BaseUrl + `/Event/UpdateEvent`, event, this.headers);
    }

    createEvent(event: any) {
        return this.http.post(this.BaseUrl + `/Event/CreateEvent`, event, this.headers);
    }

    validateEventAcceptedHours(event: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/ValidateEventAcceptedHours/${event.Project.Id}/${event.Date}`, this.headers);
    }

    getPreviousEvent(date: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetPreviosWorkingDayLastEvent/${date}`, this.headers); 
    }

    getTotalMinutes(date: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetCurrentWorkingDayLastEventTime/${date}`, this.headers); 
    }
}
