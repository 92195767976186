import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { DragAndDropService } from '../../_services/drag-and-drop/drag-and-drop.service';

import { EventHelper } from '../../_helpers/event/event-helper';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    @ViewChild('cancel') cancelElement: ElementRef;

    @Input() title: string;
    @Input() message: string;
    @Input() data: any;
    @Input() selectedDate: string;
    @Input() previousDate: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string;

    form: FormGroup;

    constructor(private activeModal: NgbActiveModal, private dragAndDropService: DragAndDropService, private eventHelper: EventHelper) { }

    ngOnInit() {}

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.cancelElement.nativeElement.focus();
        })
    }

    public decline() {
        this.activeModal.close(false);
    }

    public accept() {
        let newEvents, canSaveProject;

        this.dragAndDropService.getEvents(this.previousDate).subscribe(
            (res: any) => {
                //We can drag and drop all tasks under same project
                newEvents = res.eventList.filter(e => e.Project.Id == this.data.Id);
                canSaveProject = newEvents.every((e) => this.eventHelper.canSaveProjectOnDate(this.selectedDate, e.Project.EventAcceptanceDate));
                if (canSaveProject) {
                    newEvents.forEach((e) => this.dragAndDropService.mapAndSaveEvent(e, this.selectedDate).then((res) => res));
                    this.activeModal.close(true);
                } else {
                    this.activeModal.close(false);
                    alert("Can't copy project, hours are locked");
                }
            }
        )

    }

    public acceptDelete() {
        this.activeModal.close(true);
    }

    public dismiss() {
        this.activeModal.dismiss();
    }

    ngOnDestroy(): void {
        // this.eventList.unsubscribe();
    }

}
