﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-vacation-dialog',
    templateUrl: './vacation-dialog.component.html',
    styleUrls: ['./vacation-dialog.component.scss']
})

export class VacationDialogComponent implements OnInit {

    position: any;
    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    public dismiss() {
        this.activeModal.dismiss();
    }

    ngOnDestroy(): void {
    }
}