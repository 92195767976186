import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

    constructor() { }

    @Output() fileDropped = new EventEmitter<any>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        console.log("dragover!")
        evt.srcElement.style.border = 'groove';
        evt.srcElement.style.borderColor = '#70b1f721';
        evt.srcElement.style.backgroundColor = '#70b1f721';
        evt.preventDefault();
        evt.stopPropagation();
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.srcElement.style.border = '';
        evt.srcElement.style.borderColor = '';
        evt.srcElement.style.backgroundColor = '';
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
        evt.srcElement.style.border = '';
        evt.srcElement.style.borderColor = '';
        evt.srcElement.style.backgroundColor = '';
    }

}
