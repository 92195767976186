﻿import { Injectable } from "@angular/core";

//import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })


export class HomeHelper {

    res: number;
    position = {
        horizont: 0,
        vertical: 0
    };
    constructor() { }//private toastr: ToastrService

    getPercentPos(chevronName: string): any {

        switch (chevronName) {
            case "Rookie":
                this.position.horizont = 3;
                this.position.vertical = 4;
                break;
            case "Intern":
                this.position.horizont = 2;
                this.position.vertical = 4;
                break;
            case "Fighter":
                this.position.horizont = 1;
                this.position.vertical = 4;
                break;
            case "StormTrooper":
                this.position.horizont = 3;
                this.position.vertical = 3;
                break;
            case "Commandos":
                this.position.horizont = 2;
                this.position.vertical = 3;
                break;
            case "SpecialForces":
                this.position.horizont = 1;
                this.position.vertical = 3;
                break;
            case "JuniorOfficer":
                this.position.horizont = 3;
                this.position.vertical = 2;
                break;
            case "FieldOfficer":
                this.position.horizont = 2;
                this.position.vertical = 2;
                break;
            case "SuperDev":
                this.position.horizont = 1;
                this.position.vertical = 2;
                break;
            case "ItAryans":
                this.position.horizont = 3;
                this.position.vertical = 1;
                break;
            case "Atlant":
                this.position.horizont = 2;
                this.position.vertical = 1;
                break;
            case "TheChosenOne":
                this.position.horizont = 1;
                this.position.vertical = 1;

        }
        return this.position;
    }

    getChevronProgress(hours: number, chevronName: string): number {

        switch (chevronName) {
            case "Rookie":
                this.res = (hours / 500) * 100;
                break;
            case "Intern":
                this.res = ((hours - 500) / 500) * 100;
                break;
            case "Fighter":
                this.res = ((hours - 1000) / 1000) * 100;
                break;
            case "StormTrooper":
                this.res = ((hours - 2000) / 1000) * 100;
                break;
            case "Commandos":
                this.res = ((hours - 3000) / 3000) * 100;
                break;
            case "SpecialForces":
                this.res = ((hours - 6000) / 4000) * 100;
                break;
            case "JuniorOfficer":
                this.res = ((hours - 10000) / 3000) * 100;
                break;
            case "FieldOfficer":
                this.res = ((hours - 13000) / 8000) * 100;
                break;
            case "SuperDev":
                this.res = ((hours - 21000) / 9000) * 100;
                break;
            case "ItAryans":
                this.res = ((hours - 31000) / 9000) * 100;
                break;
            case "Atlant":
                this.res = ((hours - 40000) / 10000) * 100;
                break;
            case "TheChosenOne":
                this.res = ((hours - 50000) / 50000) * 100;

        }
        if (this.res < 1) {
            this.res = Math.ceil(this.res)
        } else {
            this.res = Math.round(this.res)
        }
        return this.res;
    }


    hoursToChevron(hours: number): string {
        if (hours <= 500)
            return "Rookie";
        if (hours <= 1000)
            return "Intern";
        if (hours <= 2000)
            return "Fighter";
        if (hours <= 3000)
            return "StormTrooper";
        if (hours <= 6000)
            return "Commandos";
        if (hours <= 10000)
            return "SpecialForces";
        if (hours <= 13000)
            return "JuniorOfficer";
        if (hours <= 21000)
            return "FieldOfficer";
        if (hours <= 30000)
            return "SuperDev";
        if (hours <= 40000)
            return "ItAryans";
        if (hours <= 50000)
            return "Atlant";
        return "TheChosenOne";
    }

}