export class TimeSpentBase<T> {
    value: T;
    key: string;
    valueFormat: string;
    label: string;
    selected: boolean;

    constructor(options: {
        value?: T,
        key?: string,
        valueFormat?: string,
        label?: string,
        selected?: boolean
    } = {}) {
        this.value = options.value;
        this.key = options.key;
        this.valueFormat = options.valueFormat;
        this.label = options.label;
        this.selected = options.selected;
    }
}
