import { Injectable } from "@angular/core";

import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })

export class EventHelper {

    constructor(private toastr: ToastrService) {}

    canSaveProjectOnDate(eventSelectedDate: string, eventAcceptanceDate: string): boolean {
        let result = true;

        if(eventSelectedDate < eventAcceptanceDate) {
            result = false;
        }

        return result;
    }

    displayToasterErrorMessages(errors: any) {
        for (let property in errors) {
            if (errors.hasOwnProperty(property)) {
                this.toastr.error(errors[property], 'Some fields are empty or incorrect');
            }
        }
    }

    replaceAndChar(event: any) {
        event.UserComment = event.UserComment.replace(/\&(?!amp;)/g, "&amp;");
    }

}