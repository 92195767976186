import { Injectable } from "@angular/core";

import { Validator } from './validator';

@Injectable({ providedIn: 'root' })

export class ForgotPassValidation {

    constructor() {}

    public validateForgotPass(data: any): any {
        
        let errors: any = {};

        if(Validator.validate(data)) {
            errors.usernameOrEmail = "Please enter username or email";
        }

        return { 
            errors : errors,
            isValid: Validator.validate(errors)
        };
    }
}