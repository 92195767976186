import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpXsrfCookieExtractor } from '@angular/common/http/src/xsrf';

@Injectable({ providedIn: 'root' })

export class UserService {

    constructor(private fb: FormBuilder, private http: HttpClient) { }

    readonly BaseUrl = environment.baseUrl;

    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    login(formData, isChecked) {
        var headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Remember': isChecked
            })
        };
        return this.http.post(this.BaseUrl + '/User/Login', JSON.stringify(formData), headers); 
    }

    forgotPass(usernameOrEmail) {
        return this.http.post(this.BaseUrl + '/User/ForgotPass', JSON.stringify(usernameOrEmail), this.headers);
    }

    restorePass(formData, tokenParams: Params) {
        return this.http.post(this.BaseUrl + '/User/RestorePass', { NewPassword: formData.Password, Token: tokenParams });
    }

    isPassEqual(formData) {
        return formData.Password == formData.ConfirmPassword ? true : false;
    }

    getUserId(): Observable<any> {
        let token = localStorage.getItem('token');
        return this.http.get<any>(this.BaseUrl + `/User/GetIdByToken/${token}`, this.headers);
    }

    isTokenValid(token) {
        let ttt = token;
        return this.http.get(this.BaseUrl + `/User/ValidateToken/${ttt}`, this.headers);
    }
}