import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ImportService, EditEventService, DataService, UserService } from '../../../_services';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as Papa from 'papaparse';
import { promise } from 'selenium-webdriver';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { forEach } from '@angular/router/src/utils/collection';


@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent {

    eventList: any[] = [];
    userID: any;

    subscription = new Subscription();
    fileContent = [];

    @Input() public fileToUpload: File = null;
    @Input() public customers: any[] = [];
    @Input() public projects: any[] = [];
    @Input() public recentProject: any[] = [];

    customersNames: any[] = [];
    fileSeparateArray: any[] = [];
    isFileValidated: boolean = false;

    lastLocked: any;
    periodLastDate: any;
    dataList: any[] = [];


    selectForm: FormGroup;
    selectFormProject: FormGroup;

    constructor(
        private activeModal: NgbActiveModal, private importService: ImportService,
        private fb: FormBuilder, private toastr: ToastrService, private dataService:
        DataService, private userService: UserService) { }

    async ngOnInit() {
        this.selectForm = this.fb.group({
            selectControl: [this.recentProject[0].Project.Customer.Name]
        });
        let projectOnLoad = this.getCorrectProjectOnLoad();
        this.selectFormProject = this.fb.group({
            selectControl: [projectOnLoad]
        });
        Papa.parse(this.fileToUpload, {
            header: true,
            skipEmptyLines: true,
            complete: (result, file) => {
                if (result.errors[0]) {
                    console.log(result.error[0]);
                    this.toastr.error(result.errors[0].message, 'Parsing error');
                }
                for (let i = 0; i < result.data.length - 1; i++) {
                    this.dataList.push(result.data[i]);
                }
            }
        });
        this.periodLastDate = this.getLastDayOfCurrentMonth();
        // delete
        let fileContent = this.readFileContent(this.fileToUpload);
        this.fileSeparateArray = (await fileContent).split("\n");
        // delete
        this.getLastLockedDate(this.recentProject[0].Project.Id);
        this.checkPeriod(this.recentProject[0].Project.Id);

        this.subscription = this.dataService.currentUserId.subscribe(id => this.userID = id);

        if (this.userID == 0) {
            this.userService.getUserId().subscribe(id => this.userID = id.userId);
        }
    }

    changeProject(customerName) {
        customerName = customerName.replace(/\d\d\W|\d\W/, "");
        customerName = customerName.substring(1);
        let currentCustomerId;
        this.projects = [];
        for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i].Name == customerName) {
                currentCustomerId = this.customers[i].Id;
            }
        }
        this.getProjectsByCustomerID(currentCustomerId);
    }

    getPeriod(projectName) {
        projectName = projectName.replace(/\d\d\W|\d\W/, "");
        projectName = projectName.substring(1);
        let projectID = this.getCurrentSelectedProjectId();
        this.getLastLockedDate(projectID);
        this.checkPeriod(projectID);
    }

    getProjectsByCustomerID(currentCustomerId) {
        this.importService.getProjectsByCustomerID(currentCustomerId).subscribe(
            (res: any) => {
                for (let i = 0; i < res.projects.length; i++) {
                    this.projects.push(res.projects[i]);
                }
                this.selectFormProject = this.fb.group({
                    selectControl: [this.projects[0].Name]
                })
                this.checkPeriod(this.projects[0].Id);
            },
            err => console.error(err)
        );
    }

    getLastLockedDate(projectId) {
        return new Promise((resolve) => {
            this.importService.getLastLockedDate(projectId).subscribe(
                (res: any) => {
                    let date = res.lastLocked;
                    date = this.convertDate(date);
                    date = this.replaceAll(date, '-', '/');
                    this.lastLocked = date;
                    resolve();
                },
                err => console.error(err)
            );
        });
    }

    checkPeriod(projectId) {
        this.getLastLockedDate(projectId).then(() => {
            let dateList = [];
            this.dataList.forEach(data => {
                dateList.push(this.convertDate(data.Started));
            });
            this.importService.validateDatesForGivenProject(dateList, projectId, this.convertDate(this.periodLastDate)).subscribe(
                (res: any) => {
                    if (res > 0) {
                        let projectName;
                        for (let i = 0; i < this.projects.length; i++) {
                            if (this.projects[i].Id == projectId) {
                                projectName = this.projects[i].Name;
                            }
                        }
                        this.toastr.success("Timesheet report is valid for project: " + projectName,
                            "Validation success");
                        this.isFileValidated = true;
                    } else {
                        this.toastr.error("We are sorry. The attached file has information where the date of work logs are from the closed period. Please fix the file or contact the Administration.", 'Validation Fail');
                        this.isFileValidated = false;
                    }
                },
                err => console.error(err)
            );
        });
    }

    createEventFromFileContent(data) { // date format must be yyyy-mm-dd
        let timeInMinutes;
        if (data["Time Spent (Hours)"].toString().includes(',')) {
            timeInMinutes = data["Time Spent (Hours)"].toString();
            timeInMinutes = timeInMinutes.replace(',', '.')
            timeInMinutes = timeInMinutes * 60;
        } else {
            timeInMinutes = data["Time Spent (Hours)"] * 60;
        }
        let userComment = data.Key + ": " + data.Title + " - " + data.Comment;
        if (userComment.includes(">")) {
            userComment = this.replaceAll(userComment, ">", " ");
        } else if (userComment.includes("<")) {
            userComment = this.replaceAll(userComment, "<", " ");
        }
        let event = {
            Project: {
                Id: this.getCurrentSelectedProjectId()
            },
            User: {
                Id: this.userID
            },
            Customer: {
                Id: this.getCurrentSelectedCustomerId()
            },
            MinutesSpent: timeInMinutes,
            MinutesEstimate: 0,
            UserComment: userComment,
            Date: moment(this.transformDate(data.Started.substr(0, data.Started.indexOf(' ')))).format('YYYY.M.D'),
            IsDeleted: false
        };
        return event;
    }

    saveEvents() {
        this.eventList = [];
        this.dataList.forEach(data => {
            this.eventList.push(this.createEventFromFileContent(data))
        });
        this.importService.createEventFromCSV(this.eventList).subscribe(
            (res: any) => {
                let minDate = res.dates[0];
                let maxDate = res.dates[res.dates.length - 1];
                minDate = minDate.substr(0, minDate.indexOf('T'));
                maxDate = maxDate.substr(0, maxDate.indexOf('T'));
                this.toastr.success("Thank you! " + this.dataList.length + "  rows have been imported for the period from " + minDate +" until " + maxDate)
            },
            err => console.error(err)
        );
        setTimeout(function () { this.activeModal.dismiss() }, 3000); 
        setTimeout(function () { window.location.reload()}, 3000); 
    }

    getLastDayOfCurrentMonth() {
        var date = new Date();
        var first_date = new Date(date); //Make a copy of the date we want the first and last days from
        first_date.setUTCDate(1); //Set the day as the first of the month

        var last_date = new Date(first_date); //Make a copy of the calculated first day
        last_date.setUTCMonth(last_date.getUTCMonth() + 1); //Add a month
        last_date.setUTCDate(0); //Set the date to 0, this goes to the last day of the previous month

        let finalDate = this.convertDate(last_date.toJSON().substring(0, 10))
        return this.replaceAll(finalDate,'-','/')
    }

    getCurrentSelectedProjectId() {
        let selectedName = this.selectFormProject.value.selectControl;
        let projectID;
        for (let i = 0; i < this.projects.length; i++) {
            if (this.projects[i].Name == selectedName) {
                projectID = this.projects[i].Id
            }
        }
        return projectID;
    }

    getCurrentSelectedCustomerId() {
        let selectedName = this.selectForm.value.selectControl;
        let customerID;
        for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i].Name == selectedName) {
                customerID = this.customers[i].Id
            }
        }
        return customerID;
    }

    getCorrectProjectOnLoad() {
        let projectOnLoad;
        let recentProjectID = this.recentProject[0].Project.Id;
        for (let i = 0; i < this.projects.length; i++) {
            if (recentProjectID == this.projects[i].Id) {
                projectOnLoad = this.projects[i].Name;
            }
        }
        return projectOnLoad;
    }

    readFileContent(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!file) {
                resolve('');
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = reader.result.toString();
                resolve(text);
            };
            reader.readAsText(file);
        });
    }

    replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    convertDate(dateString) {
        var p = dateString.split(/\D/g)
        return [p[2], p[1], p[0]].join("-")
    }

    transformDate(dateString) {
        return dateString.substr(3, 2) + "/" + dateString.substr(0, 2) + "/" + dateString.substr(6, 4);
    }

    public dismiss() {
        this.activeModal.dismiss();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        // this.eventList.unsubscribe();
    }

}
