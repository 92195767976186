import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Project } from '../../../_shared/event/project.model';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog/confirmation-dialog.service';
import { HomeService } from '../../../_services/home/home.service';

@Component({
    selector: 'app-day',
    templateUrl: './day.component.html',
    styleUrls: ['./day.component.scss'],
})
export class DayComponent implements OnInit {

    @Input() cell: any;
    @Input() month: any;
    @Input() connectedLists: any;

    @Output() onDaySelected = new EventEmitter<string>();
    @Output() onCreateEvent = new EventEmitter<string>();
    @Output() onUpdateUserStatistics = new EventEmitter<void>();

    static todaysFullDate: string;
    eventDate: string;

    eventCustomersProjects: Project[] = [];

    constructor(private datepipe: DatePipe, private confirmationDialogService: ConfirmationDialogService, private homeService: HomeService) { }

    ngOnInit() {
        if (DayComponent.todaysFullDate == null) {
            DayComponent.todaysFullDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
            this.homeService.getTodaysDate().subscribe(
                (res) => DayComponent.todaysFullDate = this.datepipe.transform(res.todaysDate, 'yyyy-MM-dd'));
        }

        this.eventDate = this.cell.EventDate.substring(0, this.cell.EventDate.length - 9);

        if (this.cell.Customers == null) {
            this.cell.Customers = [];
        };

        this.cell.Customers.forEach(customer => {
            for (let project of customer.Projects) {
                this.eventCustomersProjects.push(new Project(project.ProjectID, project.ProjectName, project.ProjectTotals, customer));
            }
        });
    }

    get getTodaysFullDate() {
        return DayComponent.todaysFullDate;
    }

    dayClicked(day: string) {
        let formattedDay = this.formatDate(day);
        this.onDaySelected.emit(formattedDay);
    }

    createClicked(day: string) {
        let formattedDay = this.formatDate(day);
        this.onCreateEvent.emit(formattedDay);
    }

    drop(event: CdkDragDrop<string[]>) {
        let customer, project;

        let isDuplicateCustomer = this.isCustomerDuplicate(event.item.data);
        let isDuplicateProject = this.isProjectDuplicate(event.item.data);

        if (event.previousContainer.id !== event.container.id) {
            this.openConfirmationDialog(event.item.data, event.container.id, event.previousContainer.id).then(
                (res) => {

                    if (res) {

                        if (!isDuplicateCustomer) {
                            event.item.data.Customer.CustomerTotals = event.item.data.TotalHours;
                            this.cell.Customers.push(Object.assign({}, event.item.data.Customer));
                        } else {
                            customer = this.cell.Customers.find(customer => customer.CustomerID == event.item.data.Customer.CustomerID);
                            customer.Projects.push(event.item.data);
                            customer.CustomerTotals += event.item.data.TotalHours;
                        }

                        if (!isDuplicateProject) {
                            event.container.data.push(Object.assign({}, event.previousContainer.data[event.previousIndex]));
                        } else {
                            project = this.eventCustomersProjects.find(project => project.Id == event.item.data.Id);
                            project.TotalHours += event.item.data.TotalHours;
                        }
                        this.cell.DailyTotals += event.item.data.TotalHours;

                        this.updateUserStatistics();
                    }

                },
                (err) => console.log(err)
            );
        }

    }

    private updateUserStatistics() {
        this.onUpdateUserStatistics.emit();
    }

    private formatDate(date: string) {
        return this.datepipe.transform(date, 'yyyy-MM-dd');
    }

    private isCustomerDuplicate(eventData: any): boolean {

        let result = this.cell.Customers.some(customer => (customer.CustomerID == eventData.Customer.CustomerID));

        return result;
    }

    private isProjectDuplicate(eventData: any): boolean {

        let result = this.eventCustomersProjects.some(project => (project.Id == eventData.Id));

        return result;
    }

    private openConfirmationDialog(data, selectedDate, previousDate): Promise<boolean> {
        return this.confirmationDialogService.confirm('Please confirm..', 'Do you want to copy tasks?', data, selectedDate, previousDate)
            .then((confirmed) => confirmed)
            .catch(() => false);
    }


}
