import {
    trigger, style, transition, animate, group} from '@angular/animations';

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        transition('none => right', [group([
            style({transform: 'translateX(-100%)'}),
            animate('500ms ease-in', style({transform: 'translateX(0%)'}))
        ]
        )]),
        transition('none => left', [group([
            animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
        ]
        )])
    ])
]