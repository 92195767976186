import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EventModel } from '../../_shared/event/event.model';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root'} )

export class DragAndDropService {

    constructor(private http: HttpClient) {}

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': this.token
        })
    };

    getEvents(date: string): Observable<any> {
        date = date.substr(0, date.length - 9);
        return this.http.get<any>(this.BaseUrl + `/Event/GetEventsByUserIdAndDate/?date=${date}`, this.headers);
    }

    mapAndSaveEvent(event: any, selectedDate: string) {
        let eventModel : EventModel;

        eventModel = {
            StartDate: selectedDate,
            Project: event.Project,
            User: event.User,
            MinutesSpent: event.MinutesSpent,
            MinutesEstimate: event.MinutesEstimate,
            UserComment: event.UserComment,
            Date: selectedDate,
            IsDeleted: event.IsDeleted,
            IsLocked: event.IsLocked,
            SpentHours: event.SpentHours,
            EstimateHours: event.EstimateHours,
            SpentMinutes: event.SpentMinutes,
            FormattedTimeSpent: event.FormattedTimeSpent
        };

        //Promise needed to sync CopyEvent with UpdateUserStatistics
        return this.http.post(this.BaseUrl + `/Event/CopyEvent`, eventModel, this.headers).toPromise()
            .then((res) => res)
            .catch((err) => { });
    }

}