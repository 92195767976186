import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../../_services/user/user.service';
import { ForgotPassValidation } from '../../../_validation/forgot-pass';

@Component({
    selector: 'app-forgot-pass',
    templateUrl: './forgot-pass.component.html',
    styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {

    usernameOrEmail = '';
    currentYear: number;

    constructor(private service: UserService, private router: Router,
        private toastr: ToastrService, private forgotPassValidation: ForgotPassValidation) { }

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
    }


    onSubmit(form: NgForm) {
        const { errors, isValid } = this.forgotPassValidation.validateForgotPass(this.usernameOrEmail);

        if(isValid) {
            this.sendRestorePasswordEmail();
        } else {
            console.log(errors.usernameOrEmail);
            this.toastr.error(errors.usernameOrEmail, "Enter username or email");
        }
    }

    private sendRestorePasswordEmail() {
        this.service.forgotPass(this.usernameOrEmail).subscribe(
            (res: any) => {
                console.log(res);
                this.toastr.success(res.message);
                this.router.navigateByUrl('/user/login');
            },
            err => {
                if (err.status == 400)
                    console.log(err.error.error);
                this.toastr.error(err.error.error, 'Invalid user');
            }
        )
    }

}
