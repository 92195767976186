import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class DataService {

    constructor() {}

    private dataSource = new BehaviorSubject({});
    currentDate = this.dataSource.asObservable();

    private customerSource = new BehaviorSubject(0);
    selectedCustomerId = this.customerSource.asObservable();

    private userSource = new BehaviorSubject(0);
    currentUserId = this.userSource.asObservable();

    sendUserId(userId: number): any {
        this.userSource.next(userId);
    }

    sendDatetime(date: any): any {
        this.dataSource.next(date)
    }

    sendCustomerId(customerId: number): any {
        this.customerSource.next(customerId);
    }

}
