import { NgModule, ErrorHandler } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent, LoginComponent, ResetPassComponent, ForgotPassComponent,
        HomeComponent, EventListComponent, EditEventComponent, CreateEventComponent } from './_components';
import { AuthGuard } from './auth/auth.guard';
import { AuthErrorService } from './_services';

const routes: Routes = [
  { path: '', redirectTo: 'user/login', pathMatch: 'full' },
  {
    path: 'user', component: UserComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgot-pass', component: ForgotPassComponent },
      { path: 'reset-pass/:token', component: ResetPassComponent, pathMatch: 'full' }
    ]
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'event-list/:date', component: EventListComponent, canActivate: [AuthGuard] },
  { path: 'edit-event/:date/:id/:source', component: EditEventComponent, canActivate: [AuthGuard] },
  { path: 'create-event/:date/:source', component: CreateEventComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AuthErrorService
    }
  ]
})
export class AppRoutingModule { }
