import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService, EventListService, EditEventService, UserService } from 'src/app/_services';
import { DatePipe } from '@angular/common';
import { SlideInOutAnimation } from 'src/app/animations';

import { EventHelper } from '../../_helpers/event/event-helper';

import { EventValidation } from '../../_validation/event-validation';
import { AutoSelectService } from '../../_services/event/auto-select.service';
import { Subscription, Observable } from 'rxjs';
import { ProjectsComponent } from '../edit-event/projects/projects.component';
import { CustomersComponent } from '../edit-event/customers/customers.component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-create-event',
    templateUrl: './create-event.component.html',
    styleUrls: ['./create-event.component.scss'],
    animations: [SlideInOutAnimation]
})
export class CreateEventComponent implements OnInit, OnDestroy {
    @ViewChild('descriptionArea') descriptionElement: ElementRef;
    @ViewChild(ProjectsComponent) projects: ProjectsComponent;
    @ViewChild(CustomersComponent) customers: CustomersComponent;

    event = {
        Project: {
            Id: 0
        },
        User: {
            Id: 0
        },
        Customer: {
            Id: 0
        },
        MinutesSpent: 0,
        MinutesEstimate: 0,
        UserComment: '',
        Date: '',
        IsDeleted: false
    };

    isCustomerEmpty = false;
    isProjectEmpty = false;
    isDescriptionEmpty = false;

    currentTimeSpent: number = 0;
    currentDescription: string = '';
    deploymentVersion: string = '';
    currentCustomerId: number = 0;
    currentProjectId: number = 0;

    displayHoursSpent: number = 0;
    displayMinutesSpent: number = 0;

    animationState = 'none';

    previosUserEvent: any;
    onDateTotalMinutes: number = 0;
    displayTotalHoursSpent: number = 0;
    displayTotalMinutesSpent: number = 0;

    subscription = new Subscription();
    source: string;
    currentYear: number;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private eventListService: EventListService,
        private dataService: DataService, private editEventService: EditEventService,
        private userService: UserService, private DatePipe: DatePipe,
        private eventValidation: EventValidation, private eventHelper: EventHelper,
        private autoSelectService: AutoSelectService) { }

    async ngOnInit() {
        if (localStorage.getItem('token') == null) {
            this.router.navigateByUrl('');
        }
        this.deploymentVersion = localStorage.getItem('deploymentVersion');
        this.event.Date = this.activatedRoute.snapshot.paramMap.get('date');
        this.source = this.activatedRoute.snapshot.paramMap.get('source');
        this.subscription = this.dataService.currentUserId.subscribe(id => this.event.User.Id = id);

        if (this.event.User.Id == 0) {
            this.userService.getUserId().subscribe(id => this.event.User.Id = id.userId);
        }

        await this.editEventService.getPreviousEvent(this.event.Date).subscribe(
            (res: any) => {
                if (res.previousEvent !== null) {
                    this.previosUserEvent = res.previousEvent;
                    this.setLatestEventToCurrentOnLoad();
                }
            },
            err => console.error(err)
        );

        await this.editEventService.getTotalMinutes(this.event.Date).subscribe(
            (res: any) => {
                if (res.totalMinutes !== null) {
                    this.onDateTotalMinutes = res.totalMinutes;
                }
            },
            err => console.error(err)
        );

        this.currentYear = new Date().getFullYear();
    }

    private setLatestEventToCurrentOnLoad() {
        this.currentCustomerId = this.previosUserEvent.Project.Customer.Id;
        this.customers.populateList();

        this.currentTimeSpent = this.previosUserEvent.MinutesSpent;
        this.currentProjectId = this.previosUserEvent.Project.Id;

        this.event.Customer.Id = this.currentCustomerId;
        this.event.Project.Id = this.currentProjectId;
        this.event.MinutesSpent = this.currentTimeSpent;

        this.formatTimeSpent();
    }

    loadProjects() {
        this.projects.populateList();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    selectTimeSpent(timeSpent: number) {
        this.currentTimeSpent = timeSpent;
        this.event.MinutesSpent = timeSpent;
        this.formatTimeSpent();
    }

    formatTimeSpent() {
        this.displayHoursSpent = Math.floor(this.currentTimeSpent / 60);
        this.displayMinutesSpent = Math.floor(this.currentTimeSpent % 60);

        this.displayTotalHoursSpent = Math.floor((this.onDateTotalMinutes + this.currentTimeSpent) / 60);
        this.displayTotalMinutesSpent = Math.floor((this.onDateTotalMinutes + this.currentTimeSpent) % 60);
    }

    changeDescription(descriptionValue) {
        this.currentDescription = descriptionValue;
        this.event.UserComment = descriptionValue;
    }

    selectCustomerId(customerValue) {
        this.currentCustomerId = customerValue;
        this.dataService.sendCustomerId(customerValue);
        this.event.Customer.Id = customerValue;
        this.autoSelectService.autoSelectDataForCustomer(customerValue, this.event.Date).subscribe(
            (res: any) => {
                if (res.previousEvent != null) {
                    this.currentProjectId = res.previousEvent.Project.Id;
                    this.currentTimeSpent = res.previousEvent.MinutesSpent;
                    this.event.MinutesSpent = this.currentTimeSpent;
                    this.event.Project.Id = this.currentProjectId;
                }
                else {
                    this.currentProjectId = 0;
                    this.currentTimeSpent = 0;
                    this.event.MinutesSpent = 0;
                    this.event.Project.Id = 0;
                }
                this.formatTimeSpent();
            }
        );
    }

    selectProjectId(projectValue) {
        this.currentProjectId = projectValue;
        this.event.Project.Id = projectValue;
    }

    selectRecentProject(data: any) {
        this.currentCustomerId = data.customerId;
        this.dataService.sendCustomerId(data.customerId);
        this.event.Customer.Id = data.customerId;

        this.currentProjectId = data.projectId;
        this.event.Project.Id = data.projectId;
    }

    onSaveClick() {
        const { errors, isValid } = this.eventValidation.validateEvent(this.event);
        const urlDate = (this.activatedRoute.snapshot.paramMap.get('date'));

        if (isValid) {
            this.eventHelper.replaceAndChar(this.event);
            this.saveEvent(urlDate);
        } else {
            this.eventHelper.displayToasterErrorMessages(errors);
        }
    }

    onCancelClick() {
        const urlDate = this.activatedRoute.snapshot.paramMap.get('date');
        if (this.source == "home") {
            this.router.navigateByUrl('/home');
        } else {
            this.router.navigateByUrl(`/event-list/${urlDate}`);
        }
    }

    previousDateArrowClick() {
        let newDate = new Date(this.event.Date);
        newDate.setDate(newDate.getDate() - 1);
        this.event.Date = this.DatePipe.transform(newDate, 'yyyy-MM-dd');

        this.eventListService.populateEventList(this.event.Date).subscribe(
            (res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.event.Date}`]);
            });
    }

    nextDateArrowClick() {
        let newDate = new Date(this.event.Date);
        newDate.setDate(newDate.getDate() + 1);
        this.event.Date = this.DatePipe.transform(newDate, 'yyyy-MM-dd');

        this.eventListService.populateEventList(this.event.Date).subscribe(
            (res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.event.Date}`]);
            });
    }

    resetAnimationState() {
        setTimeout(() => {
            this.animationState = 'none';
        }, 200);
    }

    onLogout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('month');
        sessionStorage.removeItem('year');
        this.router.navigate(['/user/login']);
    }

    saveEvent(urlDate: string) {
        this.editEventService.validateEventAcceptedHours(this.event).subscribe(
            (res: any) => {
                if (res.status) {
                    this.editEventService.createEvent(this.event).subscribe(
                        (res: any) => {
                            if (res)
                                this.dataService.sendDatetime(res.eventList);
                            this.router.navigateByUrl(`/event-list/${urlDate}`);
                        },
                        err => console.error(err)
                    );
                } else {
                    alert("Cant save a closed project");
                }
            }
        );
    }

    //private displayToasterErrorMessages(errors: any) {
    //    for (let property in errors) {
    //        if (errors.hasOwnProperty(property)) {
    //            this.toastr.error(errors[property], 'Some fields are empty or incorrect');
    //        }
    //    }
    //}

    checkFocus() {
        if (window.innerWidth > 768) {
            this.invokeFocusOnTextArea();
        };
    }

    invokeFocusOnTextArea() {
        this.descriptionElement.nativeElement.focus();
    }



}